@import "bootstrap/dist/css/bootstrap.css";
@import "@autoprog/core-client/dist/core.css";

:root {
	--login-button-color: #012c5a;
	--login-button-hover-color: #01356d;
	--login-button-active-color: #00438b;
	--aside-background: #012c5a;
}

#login-form {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0 auto;

	.form-group {
		padding-top: 16px;
		margin-bottom: 30px;
		position: relative;

		input {
			padding: 16px;
			width: 100%;
			font-size: 1.1em;

			border: 0;
			box-shadow: none;
			border-bottom: 1px solid #949494;
			outline: none;

			&:focus {
				border-bottom: 0;
				box-shadow: 0px 2px 0px 0px var(--login-button-color);
			}
		}

		input:not(:placeholder-shown) + label,
		input:focus + label {
			height: 16px;
			top: 0;
			left: 0;
			font-size: 1em;
		}
		input:focus + label {
			color: var(--login-button-color);
		}
		label {
			position: absolute;
			width: 100%;
			height: calc(100% - 16px);
			display: inline-flex;
			align-content: center;
			flex-wrap: wrap;
			left: 16px;
			font-size: 1.1em;
			transition: 200ms;
		}
	}
}

.cover-container {
	position: relative;
}

.content-svg {
	position: absolute;
	margin: 50px;
	z-index: 2;
}

#login-btn {
	height: 54px;
	font-weight: 500;

	--bs-btn-color: #fff;
	--bs-btn-bg: var(--login-button-color);
	--bs-btn-border-color: var(--login-button-color);
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: var(--login-button-hover-color);
	--bs-btn-hover-border-color: var(--login-button-hover-color);
	--bs-btn-focus-shadow-rgb: 49, 132, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: var(--login-button-active-color);
	--bs-btn-active-border-color: var(--login-button-active-color);
	--bs-btn-active-shadow: inset 0 3px 5px #00000020;
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--login-button-color);
	--bs-btn-disabled-border-color: var(--login-button-color);
}

.bottom {
	width: 100%;
	height: 20%;
	position: absolute;
	display: flex;
	justify-content: space-around;

	align-items: center;
	flex-direction: column;
	bottom: 0;
	padding: 1em;

	.aside-logo {
		margin-bottom: 0;
	}
}

.cover-aside {
	background-image: linear-gradient(180deg, #0000 20%, rgba(0,0,0,0.5) 75%), url("../assets/aside.png");
	background-color: var(--login-button-color) !important;
	background-size: auto !important;
	background-position: center 100px !important;
}

.copyright > a {
	color: white;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.copyright {
	display: block;
	font-size: 12px;
	text-align: center;
	margin: 0;
}

.cover-content {
	width: 100%;
	height: 80%;
}

#label-pass,
#label-login {
	font-weight: 500;
}

@media (orientation: landscape) and (max-width: 1279px) {
	.cover-aside {
		background-position: center !important;
	}
}

@media (min-width: 576px) and (orientation: landscape) {
	.cover-content {
		width: 62.5%;
		height: 100%;
	}

	#login-form {
		width: 50%;
	}

	.bottom {
		background: none;
		padding: 2rem;
		height: auto;

		.aside-logo {
			margin-bottom: 2rem;
		}
	}
}
